import { MsalProvider } from '@azure/msal-react';
import { CONNECT_TO_EXTERNAL_API } from '@m/constants/FeatureFlags';
import { setDefaultAuthMethod } from '@m/utils/http';
import { msalInstance } from '@m/utils/msalConfig';

import Providers from 'providers';

import Admin from './Admin';
import { AdminAppBox } from './AdminApp.styled';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function App() {
  return (
    <Providers>
      <MsalProvider instance={msalInstance}>
        <AdminAppBox>
          <Admin />
        </AdminAppBox>
      </MsalProvider>
    </Providers>
  );
}
