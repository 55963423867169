import { IdNameObject } from '@m/types';
import { apiFetch } from '@m/utils/http';

const BASE_ROUTE = '/admin/enterprise';

// TODO: Consolidate this and the request app enum
export enum HospitalityStayType {
  Overnight = 'Overnight',
  Transient = 'Transient',
}

export type CreateIntakePartnerFormPayload = {
  name: string;
  siteId: number;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phoneNumber: string;
};

export type IntakePartner = CreateIntakePartnerFormPayload & { id: number; enterpriseId: number };

export type HospitalityRateDetailForm = {
  stayType: HospitalityStayType;
  isOversizedVehicle: boolean;
  intervalRateInDollars: number;
  intervalPeriodHours: number;
  gracePeriodMinutes: number;
};

export type HospitalityRate = {
  gracePeriodMinutes: number;
  id: string;
  intervalPeriodHours: number;
  intervalRate: number;
  oversizedVehicle: boolean;
  stayType: IdNameObject;
};

const HospitalityService = {
  getEnterpriseIntakePartner: ({ enterpriseId }: { enterpriseId: number }) =>
    apiFetch(`${BASE_ROUTE}/${enterpriseId}/intake-partners`),

  getEnterpriseIntakePlans: ({ enterpriseId }: { enterpriseId: string }) =>
    apiFetch(`${BASE_ROUTE}/${enterpriseId}/intake-plans`),

  createEnterpriseIntakePartner: ({
    enterpriseId,
    form,
  }: {
    enterpriseId: number;
    form: CreateIntakePartnerFormPayload;
  }) => apiFetch(`${BASE_ROUTE}/${enterpriseId}/intake-partner`, { method: 'POST', body: form }),

  updateEnterpriseIntakePartner: ({
    enterpriseId,
    intakePartnerId,
    form,
  }: {
    enterpriseId: number;
    intakePartnerId: number;
    form: CreateIntakePartnerFormPayload;
  }) =>
    apiFetch(`${BASE_ROUTE}/${enterpriseId}/intake-partner/${intakePartnerId}`, {
      method: 'PUT',
      body: form,
    }),

  getEnterpriseIntakePartnerPlan: ({
    enterpriseId,
    intakePartnerId,
  }: {
    enterpriseId: number;
    intakePartnerId: number;
  }) => apiFetch(`${BASE_ROUTE}/${enterpriseId}/intake-plan/intake-partner/${intakePartnerId}`),

  createEnterpriseIntakePartnerPlan: ({
    enterpriseId,
    intakePartnerId,
    siteId,
    form,
  }: {
    enterpriseId: number;
    intakePartnerId: number;
    siteId: number;
    form: HospitalityRateDetailForm[];
  }) =>
    apiFetch(
      `${BASE_ROUTE}/${enterpriseId}/intake-plan/intake-partner/${intakePartnerId}/sites/${siteId}`,
      {
        method: 'POST',
        body: form,
      },
    ),

  getEnterpriseIntakePartnerPlanRates: ({
    enterpriseId,
    intakePlanId,
  }: {
    enterpriseId: number;
    intakePlanId: string;
  }) => apiFetch(`${BASE_ROUTE}/${enterpriseId}/intake-plan/${intakePlanId}/hospitality-rates`),

  updateEnterpriseIntakePartnerPlanRate: ({
    enterpriseId,
    rateId,
    form,
  }: {
    enterpriseId: number;
    rateId: string;
    form: HospitalityRateDetailForm;
  }) =>
    apiFetch(`${BASE_ROUTE}/${enterpriseId}/hospitality-rates/${rateId}`, {
      method: 'PUT',
      body: form,
    }),
};

export default HospitalityService;
