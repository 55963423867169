import { heapAddEventProperties, heapAddUserProperties } from '@m/utils/heap';

export const trackAdminUser = (data: {
  email: string;
  permissionGroups: [{ id: number; name: string }];
}) => {
  // reduce all permission names into a comma separated string
  const permissionGroups = data?.permissionGroups?.reduce((acc, curr) => `${acc + curr.name},`, '');

  heapAddUserProperties(data);
  heapAddEventProperties({ email: data.email, permissionGroups });
};
