import { Site } from '@m/types/api';
import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

export const getSites = createEffect({
  handler: () =>
    apiFetch('/sites/search', {
      method: 'POST',
      body: {},
    }),
});

export const updateSite = createEffect({
  handler: ({ site }: { site: Site }) => apiFetch(''), // TODO
});
