import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

export const getLocationValidations = createEffect(({ id }: { id: number }) =>
  apiFetch(`/admin/site/${id}/validations/v2`),
);

export const getValidationDetail = createEffect(({ validationId }: { validationId: number }) =>
  apiFetch(`/admin/validation/${validationId}`),
);
