import { useState } from 'react';

import { KEYCLOAK_ENABLED } from '@m/config/env';
import { RoleName } from '@m/types/api/iam/Iam';
import { useUnit } from 'effector-react';

import { hasRoles } from 'apps/admin/permissions/RouteManager';

import { authStore } from '../state/auth';

export default function useSuperAdminCheck() {
  const { currentUser } = useUnit(authStore);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  if (KEYCLOAK_ENABLED) return hasRoles([RoleName.SUPER_ADMIN]);

  if (currentUser) {
    if (currentUser.permissionGroups.length >= 1) {
      currentUser.permissionGroups.forEach((p) => {
        if (p.name === 'Super Admin' && !isSuperAdmin) setIsSuperAdmin(true);
      });
    }
  }

  return isSuperAdmin;
}
