import { IamRole } from '@m/types/api/iam/Iam';
import { createStore, Effect } from 'effector';

import { applyReducers, DoneHandler } from 'effector-utils';

import { getOperatorRoleDetails } from './actions';

export type IamState = {
  iamMapping: {
    [key: string]: IamRole;
  };
};

export const initialState: IamState = {
  iamMapping: {},
};

const store = createStore(initialState);

type Reducers = {
  getOperatorRoleDetails: {
    action: Effect<Parameters<typeof getOperatorRoleDetails>[0], IamRole | null>;
    done: DoneHandler<Parameters<typeof getOperatorRoleDetails>[0], IamState, IamRole | null>;
  };
};

export const reducers: Reducers = {
  getOperatorRoleDetails: {
    action: getOperatorRoleDetails,
    done: (state, { params, result }) =>
      result !== null
        ? {
            ...state,
            iamMapping: {
              ...state.iamMapping,
              [params.roleId]: result,
            },
          }
        : state,
  },
};

export default applyReducers({ store, reducers });
