/**
 * Should this page be an "Internal" or super admin / dev page instead?
 */
import { lazy, Suspense } from 'react';

import { RoleName } from '@m/types/api/iam/Iam';
import { Switch } from 'react-router-dom';

import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';

const ViolationDashboard = lazy(() => import('apps/admin/containers/Violation/ViolationDashboard'));

export const violationsRoutesConfig = [
  {
    exact: true,
    path: '/violations',
    breadcrumb: 'Violations',
    roles: [RoleName.MANAGER, RoleName.CUSTOMER_SUPPORT],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ViolationDashboard />
      </Suspense>
    ),
  },
];

export default function ViolationRoutes() {
  return (
    <Switch>
      {violationsRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
