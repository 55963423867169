import { LocationSubscriptionPayload } from '@m/types/api';
import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

export const getSubscriptionPlanById = createEffect({
  handler: (subscriptionPlanId: number) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}`),
});

export const getLocationSubscriptionPlans = createEffect({
  handler: ({ siteId }: { siteId: number }) => apiFetch(`/admin/subscription/plans/site/${siteId}`),
});

export const createLocationSubscriptionPlan = createEffect({
  handler: async ({
    siteId,
    subscriptionPlan,
  }: {
    siteId: number;
    subscriptionPlan: LocationSubscriptionPayload;
  }) =>
    apiFetch(`/admin/subscription/plan/site/${siteId}`, {
      method: 'POST',
      body: subscriptionPlan,
    }),
});

export const updateSubscriptionPlan = createEffect({
  handler: ({
    subscriptionPlanId,
    subscriptionPlan,
  }: {
    subscriptionPlanId: number;
    subscriptionPlan: LocationSubscriptionPayload;
  }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}`, {
      method: 'PUT',
      body: subscriptionPlan,
    }),
});

export const deleteSubscriptionPlan = createEffect({
  handler: ({ subscriptionPlanId }: { subscriptionPlanId: number }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}`, {
      method: 'DELETE',
    }),
});
