import React, { lazy, Suspense } from 'react';

import { AdminRouterParams } from '@m/types';
import { NavLink, Switch } from 'react-router-dom';

import {
  DetailPageWrapper,
  FormPageWrapper,
  ListPageWrapper,
  InvoiceViewPageWrapper,
} from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import { navLinkStyle } from 'apps/admin/components/BreadCrumbs/active';
import { DynamicCrumb } from 'apps/admin/components/BreadCrumbs/DynamicCrumb';

const EnterpriseList = lazy(() => import('apps/admin/containers/Enterprise/ListContainer'));
const CreateEnterprise = lazy(() => import('apps/admin/containers/Enterprise/CreateContainer'));
const EnterpriseDetail = lazy(() => import('apps/admin/containers/Enterprise/DetailContainer'));
const EnterpriseEdit = lazy(() => import('apps/admin/containers/Enterprise/EditContainer'));
const EnterpriseSubCreate = lazy(
  () => import('apps/admin/containers/Enterprise/CreateSubContainer'),
);
const EnterpriseSubDetail = lazy(
  () => import('apps/admin/containers/Enterprise/SubDetailContainer'),
);

const InvoicePDFContainer = lazy(
  () => import('apps/admin/containers/Enterprise/InvoiceContainerNew'),
);
const InvoiceViewContainer = lazy(
  () => import('apps/admin/containers/Invoice/InvoiceViewContainer'),
);
const ParkingPassLocationDetailsPage = lazy(
  () => import('apps/admin/containers/Enterprise/ParkingPassPlanDetailsPage'),
);
const ValidationPartnerDetailsPage = lazy(
  () => import('apps/admin/containers/Validation/ValidationPartnerDetailsPage'),
);
const ValidationDetailsPage = lazy(
  () => import('apps/admin/containers/Validation/ValidationDetailsPage'),
);

const IntakePartnerDetailsPage = lazy(
  () => import('apps/admin/containers/Enterprise/IntakePartnerDetailsPage'),
);

export const enterpriseRoutesConfig = [
  {
    exact: true,
    path: '/enterprise',
    breadcrumb: 'Enterprises',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <EnterpriseList />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/enterprise/create',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper>
          <CreateEnterprise />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/enterprise/:enterpriseId',
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink to={`/enterprise/${params.enterpriseId}/details`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="enterprise" itemId={params.enterpriseId} />
      </NavLink>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/details',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <EnterpriseDetail />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/edit',
    breadcrumb: 'Edit',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper>
          <EnterpriseEdit />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/subscription/create',
    breadcrumb: 'New Subscription',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper>
          <EnterpriseSubCreate />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/subscription/:subscriptionId/details',
    component: AuthenticatedRoute,
    breadcrumb: 'Subscription',
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <EnterpriseSubDetail />
        </DetailPageWrapper>
      </Suspense>
    ),
  },

  {
    exact: true,
    path: '/enterprise/:enterpriseId/invoice/:invoiceId',
    breadcrumb: 'Invoice',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <InvoiceViewPageWrapper flex={1}>
          <InvoiceViewContainer />
        </InvoiceViewPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/invoice/:invoiceId/pdf',
    breadcrumb: 'Invoice PDF',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper flex={1}>
          <InvoicePDFContainer />
        </DetailPageWrapper>
      </Suspense>
    ),
  },

  /** Passes */
  {
    exact: true,
    path: '/enterprise/:enterpriseId/parking-pass-plans/:parkingPassPlanId',
    breadcrumb: ({
      match: { params },
    }: {
      match: { params: { enterpriseId: string; parkingPassPlanId: string } };
    }) => (
      <NavLink
        to={`/enterprise/${params.enterpriseId}/parking-pass-plans/${params.parkingPassPlanId}`}
        activeStyle={navLinkStyle}
      >
        <DynamicCrumb itemType="pass-plan" itemId={params.parkingPassPlanId} />
      </NavLink>
    ),
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper flex={1}>
          <ParkingPassLocationDetailsPage />
        </DetailPageWrapper>
      </Suspense>
    ),
  },

  /**
   * Validation Partners
   */
  {
    path: '/enterprise/:enterpriseId/validating-location/:validatingLocationId',
    breadcrumb: ({
      match: {
        params: { enterpriseId, validatingLocationId },
      },
    }: {
      match: { params: AdminRouterParams };
    }) => (
      <NavLink to={`/enterprise/${enterpriseId}/validating-location/${validatingLocationId}`}>
        <DynamicCrumb itemType="validating-location" itemId={validatingLocationId} />
      </NavLink>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/validating-location/:validatingLocationId',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper flex={1}>
          <ValidationPartnerDetailsPage />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/validating-location/:validatingLocationId/sign/:entLocationSignId',
    component: AuthenticatedRoute,
    breadcrumb: 'Validation',
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper flex={1}>
          <ValidationDetailsPage />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/enterprise/:enterpriseId/intake-partner/:intakePartnerId',
    component: AuthenticatedRoute,
    breadcrumb: 'Intake Partner',
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper flex={1}>
          <IntakePartnerDetailsPage />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
];

export default function EnterpriseRoutes() {
  return (
    <Switch>
      {enterpriseRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
