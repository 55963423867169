import { IamUser } from '@m/types/api/iam/Iam';
import { createEffect, createEvent } from 'effector';

import { UserAdminService } from 'apps/admin/services';

export const getUsers = createEffect(UserAdminService.getUsers);
export const getUserById = createEffect(UserAdminService.getUserById);
export const createUserV2 = createEffect(UserAdminService.createUser);
export const updatePermissions = createEffect(UserAdminService.updatePermissions);
export const assignUserToEmployer = createEffect(UserAdminService.setUserEmployer);
export const setUserActive = createEffect(UserAdminService.setUserActive);
export const setSelectedUser = createEvent<IamUser | undefined | null>();
