import { User } from '@m/types/api';
import { createStore, Effect } from 'effector';

import { applyReducers } from 'effector-utils';

import { getCurrentUser, login, loginMicrosoft, logout } from './actions';

import type { APIResponse } from '@m/utils/http';
import type { DoneHandler } from 'effector-utils';

type State = {
  authenticated: boolean | null;
  currentUser: User | null;
};

export const initialState: State = {
  authenticated: null,
  currentUser: null,
};

const store = createStore(initialState);

type Reducers = {
  getCurrentUser: {
    action: Effect<void, APIResponse>;
    done: DoneHandler<void, State>;
  };
  login: {
    action: Effect<Parameters<typeof login>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof login>[0], State>;
  };
  loginMicrosoft: {
    action: Effect<Parameters<typeof loginMicrosoft>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof loginMicrosoft>[0], State>;
  };
  logout: {
    action: Effect<Parameters<typeof logout>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof logout>[0], State>;
  };
};

export const reducers: Reducers = {
  getCurrentUser: {
    action: getCurrentUser,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      authenticated: !!success,
      currentUser: success ? data.user : null,
    }),
  },
  login: {
    action: login,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      authenticated: !!success,
      currentUser: success ? data.user : null,
    }),
  },
  loginMicrosoft: {
    action: loginMicrosoft,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      authenticated: !!success,
      currentUser: success ? data.user : null,
    }),
  },
  logout: {
    action: logout,
    done: (state, { result: { success } = {} }) => ({
      ...state,
      authenticated: success ? false : state.authenticated,
      currentUser: success ? null : state.currentUser,
    }),
  },
};

export default applyReducers({ store, reducers });
