/**
 * Should this page be an "Internal" or super admin / dev page instead?
 */
import { lazy, Suspense } from 'react';

import { RoleName } from '@m/types/api/iam/Iam';
import { Switch } from 'react-router-dom';

import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';

const FeatureFlagsPage = lazy(() => import('apps/admin/containers/FeatureFlagsPage'));

export const featureFlagsRoutesConfig = [
  {
    exact: true,
    path: '/feature-flags',
    breadcrumb: 'Feature Flags',
    roles: [RoleName.OPERATIONAL_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FeatureFlagsPage />
      </Suspense>
    ),
  },
];

export default function FeatureFlagRoutes() {
  return (
    <Switch>
      {featureFlagsRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
