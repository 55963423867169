import { apiFetch } from '@m/utils/http';

export const IntegrationService = {
  getIntegrationOptions: ({ zoneId }: { zoneId: number | string }) =>
    apiFetch(`/admin/site/${zoneId}/external-zone-integration-types`),

  getSavedIntegrations: ({ zoneId }: { zoneId: number | string }) =>
    apiFetch(`/admin/site/${zoneId}/external-zone-integrations`),

  saveIntegrations: ({
    zoneId,
    externalZones,
  }: {
    zoneId: number | string;
    externalZones: { id: string; integration: string }[];
  }) =>
    apiFetch(`/admin/site/${zoneId}/save-external-zone-integrations`, {
      method: 'POST',
      body: { externalZones },
    }),
};
