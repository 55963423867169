import { apiFetch } from '@m/utils/http';

export const IntakePartnerService = {
  /**
   * Fetch intake partner list
   */
  getIntakePartners: () => apiFetch('/admin/intake-partners'),

  /**
   * Create new intake partner
   */
  createIntakePartner: (body: { name: string }) =>
    apiFetch('/admin/intake-partner/create', {
      method: 'POST',
      body,
    }),

  /**
   * Delete intake partner
   */
  deleteIntakePartner: ({ partnerId }: { partnerId: number }) =>
    apiFetch(`/admin/intake-partner/${partnerId}/delete`, { method: 'POST' }),
};
