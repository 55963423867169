import React, { lazy, Suspense } from 'react';

import { AdminRouterParams } from '@m/types';
import { RoleName } from '@m/types/api/iam/Iam';
import { NavLink, Switch } from 'react-router-dom';

import { ListPageWrapper } from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import { navLinkStyle } from 'apps/admin/components/BreadCrumbs/active';
import { DynamicCrumb } from 'apps/admin/components/BreadCrumbs/DynamicCrumb';

const VisitCenterContainer = lazy(
  () => import('apps/admin/containers/visits/VisitCenterContainer'),
);
const VisitHistoryContainer = lazy(
  () => import('apps/admin/containers/visits/VisitHistoryContainer'),
);

export const visitRoutesConfig = [
  {
    exact: true,
    path: '/visit-center',
    breadcrumb: 'Visit Center',
    roles: [RoleName.OPERATIONAL_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <VisitCenterContainer />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/visits/:visitId',
    roles: [RoleName.OPERATIONAL_ADMIN],
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink to={`/visits/${params.visitId}`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="visits" itemId={params.visitId} />
      </NavLink>
    ),
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <VisitHistoryContainer />
        </ListPageWrapper>
      </Suspense>
    ),
  },
];

export default function VisitRoutes() {
  return (
    <Switch>
      {visitRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
