import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

import type { RateSpanSchedulePost, SpecialEventRatePost } from '@m/types/api';

/**
 * Standard Site Rates
 */
export const fetchSiteRateSchedules = createEffect({
  handler: ({ siteId }: { siteId: number }) =>
    apiFetch(`/admin/site/${siteId}/rate-schedule`, { method: 'GET' }),
});

// Rate Schedule
export const createSiteRateSchedule = createEffect({
  handler: ({
    siteId,
    rateSpanSchedules,
    specialEventSchedule,
  }: {
    siteId: number;
    rateSpanSchedules: RateSpanSchedulePost[];
    specialEventSchedule: SpecialEventRatePost[];
  }) =>
    apiFetch(`/admin/site/${siteId}/rate-schedule`, {
      method: 'POST',
      body: {
        rateSpanSchedules,
        specialEventSchedule: {
          specialEventRates: specialEventSchedule,
        },
      },
    }),
});

// Unused for now
export const testSiteRateSchedule = createEffect({
  handler: ({ rateScheduleId }: { rateScheduleId: number }) =>
    apiFetch(
      // TODO: update type
      `/admin/rate-schedule/${rateScheduleId}/calculate`,
      { method: 'POST' },
    ),
});
