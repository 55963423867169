import { Operator } from '@m/types/api/Operator/Operator';
import { apiFetch } from '@m/utils/http';

let OPERATOR_CACHE: Operator[] | null = null;

export const OperatorService = {
  /**
   * Only operator resources, without Metropolis Platform
   * Was needed to remove a circular dependency between IamOperatorService and IamUserService
   */
  getOperators: async () => {
    if (OPERATOR_CACHE) {
      return [...OPERATOR_CACHE];
    }
    const res = await apiFetch<Operator[]>('/v2/operator', { statusCodes: true });
    if (res.success && res.data) {
      OPERATOR_CACHE = res.data;
      return res.data;
    }

    throw new Error('Could not fetch operators');
  },

  createOperator: async (body: { name: string }) =>
    apiFetch('/v2/operator', {
      method: 'POST',
      statusCodes: true,
      body,
    }),
};
