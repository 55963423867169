import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

import type { WhitelistedPhoneForm } from '@m/types/api';

export const getWhitelistedPhones = createEffect({
  handler: ({ subscriptionPlanId }: { subscriptionPlanId: number }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}/whitelisted-phones`),
});

export const addWhitelistedPhones = createEffect({
  handler: ({
    subscriptionPlanId,
    whitelistedPhoneForm,
  }: {
    subscriptionPlanId: number;
    whitelistedPhoneForm: WhitelistedPhoneForm;
  }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}/whitelisted-phones`, {
      method: 'POST',
      body: whitelistedPhoneForm,
    }),
});

export const deleteWhitelistedPhone = createEffect({
  handler: ({
    whitelistedPhoneId,
    subscriptionPlanId,
  }: {
    whitelistedPhoneId: number;
    subscriptionPlanId: number;
  }) =>
    apiFetch(
      `/admin/subscription/plan/${subscriptionPlanId}/whitelisted-phone/${whitelistedPhoneId}`,
      {
        method: 'DELETE',
      },
    ),
});
