import { apiFetch } from '@m/utils/http';

import { AddMemberDTO } from '../containers/LocationGroup/TaxContainer/types';

export const TaxService = {
  /**
   * Fetch exemptions
   */
  getLocationTaxExemptions: ({ locationId }: { locationId: number }) =>
    apiFetch(`/admin/site-group/${locationId}/tax-exemptions`),

  /**
   * Update tax exemption member expiry
   */
  updateTaxExemptionForMember: ({
    locationId,
    taxId,
    userExemptionId,
    expiryDate,
  }: {
    locationId: number;
    taxId: string;
    userExemptionId: string;
    expiryDate: string;
  }) =>
    apiFetch(`/admin/site-group/${locationId}/tax-exemptions/${taxId}/update/${userExemptionId}`, {
      method: 'PUT',
      body: {
        expiryDate,
      },
    }),

  /**
   * Fetch Exemption members
   */
  getMembers: ({ locationId, taxId }: { locationId: number; taxId: string }) =>
    apiFetch(`/admin/site-group/${locationId}/tax-exemptions/${taxId}/members`),

  /**
   * Add tax exemption to location
   */
  addTaxExemptionToLocation: ({
    locationId,
    displayName,
    taxExemptionRate,
  }: {
    locationId: number;
    displayName: string;
    taxExemptionRate: string;
  }) =>
    apiFetch(`/admin/site-group/${locationId}/tax-exemptions/create`, {
      method: 'POST',
      body: {
        taxExemptionRate: Number(taxExemptionRate),
        displayName,
      },
    }),

  /**
   * Add member to tax exemption
   */
  addMemberToTaxExemption: ({
    locationId,
    taxId,
    values,
  }: {
    locationId: number;
    taxId: string;
    values: AddMemberDTO;
  }) =>
    apiFetch(`/admin/site-group/${locationId}/tax-exemptions/${taxId}/add`, {
      method: 'POST',
      body: values,
    }),
};
