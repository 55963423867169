import React, { lazy, Suspense } from 'react';

import { KEYCLOAK_ENABLED } from '@m/config/env';
import { isProd } from '@m/constants/FeatureFlags';
import { RoleName } from '@m/types/api/iam/Iam';
import { Switch } from 'react-router-dom';

import { ListPageWrapper } from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import useSuperAdminCheck from 'apps/admin/hooks/useSuperAdminCheck';

const NotificationContainer = lazy(() => import('apps/admin/containers/NotificationContainer'));
const BulkEntSubCreateContainer = lazy(
  () => import('apps/admin/containers/BulkEntSubCreateContainer'),
);
const VisitChargeManagementPage = lazy(
  () => import('apps/admin/containers/VisitChargeManagementPage'),
);
const ManagerCleanupPage = lazy(() => import('apps/admin/containers/ManagerCleanupPage'));

export const devToolRoutesConfig = [
  {
    exact: true,
    path: '/notification-center',
    breadcrumb: 'Notifications',
    roles: [RoleName.SUPER_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <NotificationContainer />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/visit-charge-management',
    breadcrumb: 'Visit Charge Management',
    roles: [RoleName.SUPER_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <VisitChargeManagementPage />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/bulk-creator',
    breadcrumb: 'Create Bulk Enterprises/Subscriptions',
    roles: [RoleName.SUPER_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <BulkEntSubCreateContainer />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/manager-cleanup',
    breadcrumb: 'Manager app cleanup',
    roles: [RoleName.SUPER_ADMIN],
    component: !isProd && KEYCLOAK_ENABLED ? AuthenticatedRoute : null,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <ManagerCleanupPage />
        </ListPageWrapper>
      </Suspense>
    ),
  },
];

export default function DevToolRoutes() {
  const isSuperAdmin = useSuperAdminCheck();

  if (isSuperAdmin) {
    return (
      <Switch>
        {devToolRoutesConfig.map(
          ({ component: Component, breadcrumb, ...props }) =>
            Component && <Component key={props.path} {...props} />,
        )}
      </Switch>
    );
  }
  return null;
}
