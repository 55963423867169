import React, { lazy, Suspense } from 'react';

import { RoleName } from '@m/types/api/iam/Iam';
import { NavLink, Switch } from 'react-router-dom';

import { ListPageWrapper } from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import { navLinkStyle } from 'apps/admin/components/BreadCrumbs/active';
import { DynamicCrumb } from 'apps/admin/components/BreadCrumbs/DynamicCrumb';

const CustomerSearchContainer = lazy(
  () => import('apps/admin/containers/Customers/SearchContainer'),
);
const CustomerDetailView = lazy(() => import('apps/admin/containers/Customers/CustomerDetailView'));

export const customerRoutesConfig = [
  {
    exact: true,
    path: '/customers',
    breadcrumb: 'Customers',
    roles: [
      RoleName.SUPER_ADMIN,
      RoleName.LEADER,
      RoleName.OPERATIONAL_ADMIN,
      RoleName.CUSTOMER_SUPPORT,
    ],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <CustomerSearchContainer />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/customers/:customerId',
    roles: [
      RoleName.SUPER_ADMIN,
      RoleName.LEADER,
      RoleName.OPERATIONAL_ADMIN,
      RoleName.CUSTOMER_SUPPORT,
    ],
    breadcrumb: ({ match: { params } }: { match: { params: { customerId: string } } }) => (
      <NavLink to={`/customers/${params.customerId}`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="users" itemId={params.customerId} />
      </NavLink>
    ),
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <CustomerDetailView />
        </ListPageWrapper>
      </Suspense>
    ),
  },
];

export default function CustomerRoutes() {
  return (
    <Switch>
      {customerRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
