import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

import type { CreateParkingPassPlanRequest, UpdateParkingPassPlanRequest } from '@m/types/api';

/**
 * Parking pass plans
 */
export const getSiteParkingPassPlans = createEffect({
  handler: ({ zoneUuid }: { zoneUuid: string }) =>
    apiFetch(`/v2/sites/${zoneUuid}/pass-plans`, { method: 'GET' }),
});

export const createSiteParkingPassPlan = createEffect({
  handler: ({
    zoneUuid,
    parkingPassPlan,
  }: {
    zoneUuid: string;
    parkingPassPlan: CreateParkingPassPlanRequest;
  }) =>
    apiFetch(`/v2/sites/${zoneUuid}/pass-plans`, {
      method: 'POST',
      body: parkingPassPlan,
    }),
});

export const updateSiteParkingPassPlan = createEffect({
  handler: ({
    zoneUuid,
    planId,
    updates,
  }: {
    zoneUuid: string;
    planId: string;
    updates: UpdateParkingPassPlanRequest;
  }) =>
    apiFetch(`/v2/sites/${zoneUuid}/pass-plans/${planId}`, { method: 'PUT', body: { ...updates } }),
});

export const deleteSiteParkingPassPlan = createEffect({
  handler: ({ zoneUuid, planId }: { zoneUuid: string; planId: string }) =>
    apiFetch(`/v2/sites/${zoneUuid}/pass-plans/${planId}`, { method: 'DELETE', body: { planId } }),
});
