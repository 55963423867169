import { apiFetch } from '@m/utils/http';

export const FeatureFlagService = {
  /**
   * Fetch available feature flags
   */
  getFeatureFlags: () => apiFetch('/admin/feature'),

  /**
   * Get feature flag setting - requires super admin permissions
   */
  getFeatureFlagSetting: ({ name }: { name: string }) => apiFetch(`/admin/feature/${name} `),

  /**
   * Get specific feature flag setting
   * TODO: make this route non customer app related?
   */
  getFeatureFlagSettingCustomer: ({
    name,
    siteId,
    enterpriseId,
  }: {
    name: string;
    siteId?: number;
    enterpriseId?: number;
  }) =>
    apiFetch(`/customer/feature/${name}`, {
      params: {
        siteId,
        enterpriseId,
      },
    }),

  /**
   * Update a feature flag setting
   */
  setFeatureFlagSetting: ({
    name,
    enabled,
    sites,
    enterprises,
  }: {
    name: string;
    enabled: boolean;
    sites?: number[];
    enterprises?: number[];
  }) =>
    apiFetch(`/admin/feature/${name}`, {
      method: 'POST',
      body:
        (sites && sites.length > 0) || (enterprises && enterprises.length > 0)
          ? {
              ...(sites ? { sites } : {}),
              ...(enterprises ? { enterprises } : {}),
            }
          : { enabled },
    }),

  /**
   * Archive feature flag
   */
  archiveFeatureFlag: ({ name }: { name: string }) =>
    apiFetch(`/admin/feature/${name}`, { method: 'DELETE' }),

  /**
   * Create new feature flag
   */
  createFeatureFlag: ({
    name,
    enabled,
    sites,
  }: {
    name: string;
    enabled?: boolean;
    sites?: number[];
  }) =>
    apiFetch(`/admin/feature/${name}`, {
      method: 'POST',
      body: sites ? { sites } : { enabled },
    }),
};
